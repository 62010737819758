.main{
    width: 50%;
    min-width: 50%;
    background-color: #F5F5F5;
    border-radius: 10px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
}

@media screen and (max-width: 915px) {
    .main{
        width: 90%;
        padding: 20px;

        .buttons{
            width: 100%;
            display: flex;
            justify-content: center;
            button{
                max-width: 50%;
                gap: 10px;
            }
        }
    }

}