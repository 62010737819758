.mainButton{
    height: fit-content;
    width: 200px;
    padding: 10px 8px;
    background-color: #0069CA;
    color: white;
    font-weight: 800;
    font-size: 14px;
    border-radius: 5px;
    border: none;
    cursor: pointer;

    &:hover{
        filter: opacity(0.5);
    }

    &:disabled{
        background-color: #8f8f8f;
    }
}

.greenButton{
    height: fit-content;
    width: 200px;
    padding: 10px 8px;
    background-color: #50C878;
    color: white;
    font-weight: 800;
    font-size: 14px;
    border-radius: 5px;
    border: none;
}

.ghostButton{
    height: fit-content;
    width: 200px;
    padding: 10px 8px;
    color: #0069CA;
    background-color: transparent;
    font-weight: 800;
    font-size: 14px;
    border-radius: 5px;
    border: none;
    cursor: pointer;

    &:hover{
        filter: opacity(0.5);
    }
}

.secondaryButton{
    width: 200px;
    padding: 10px 8px;
    color: #0069CA;
    background-color: #f5f5f5;
    font-weight: 800;
    font-size: 14px;
    border-radius: 5px;
    border: none;
    cursor: pointer;

    &:hover{
        filter: opacity(0.5);
    }
}


@media screen and (max-width: 915px) {
    .secondaryButton, .mainButton{
    }
}