.main {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
 
  p{
    margin: 0;
  }

  .header {
    width: 100%;
    height: fit-content;
  }

  

  .body {
    max-height: 80%;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #a19b9b;
    }

    box-sizing: border-box;
    padding: 20px;

    ._liste {
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding-bottom: 50px;

      .__intestazione{
        display: flex;
        justify-content: space-between;
      }

      .__wrapperDesktop {
        display: flex;
        gap: 10px;

    justify-content: center;
      }

      .__wrapperMobile{
        display: none;
      }
    }

    ._cta {
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      padding: 0 20px;
      position: fixed;
      bottom: 30px;
      left: 45%;

      :nth-child(1){
        display: none;
      }
    }

    ._procedura {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .__greyWrapper {
        background-color: #f5f5f5;
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        // border-radius: 6px;

        .fase{
          display: flex;
          flex-direction: column;
          gap: 5px;
          p {
            margin: 0;
            font-size: 18px;
          }

          .data{
            p{
                width: 20%;
            }
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
        }

        

        
      }
      
      padding-bottom: 50px;

    }
  }
}

@media screen and (max-width: 915px) {
  .main {
   
    .body {

      ._liste{

        .__wrapperDesktop{
          display: none;
        }

        .__wrapperMobile{
          display: block;

          .accordionPreview{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 30px;

            ._listaMobileLogo{
              width: 50px;
            }
  
            .round{
                width: 40px;
                height: 40px;
                border: 1px white solid;
                border-radius: 50%;
                background-color: yellow;
            }
          }
        }
      }

      ._procedura{
        display: none;
      }

      ._cta {
        width: 100%;
        position: fixed;
        bottom: 30px;
        left: 0;

        :nth-child(1){
          display: block;
        }
      }
    }
  }
}
