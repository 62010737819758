.main{
    box-sizing: border-box;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    gap: 20px;
  
    ._header{
      width: 100%;
      height: fit-content;
    }
  
    ._body{
      box-sizing: border-box;
      width: 100%;
        padding: 20px 50px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap:20px;
        overflow-y: scroll;
        overflow-x: hidden;
    
        
    
        &::-webkit-scrollbar {
          width: 5px;
          height: 5px;
        }
    
        &::-webkit-scrollbar-track {
          border-radius: 10px;
        }
    
        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background-color: #a19b9b;
        }
    

        
    }

  }

  
  @media screen and (max-width: 915px) {

    .main{
      ._body{
        padding: 10px;
      }
    }

  }