.main{
    background-color: #f5f5f5;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;

    p{
        margin: 0;
    }

    .closeButton{
        display: flex;
        justify-content: flex-end;

        img{
            cursor: pointer;
        }
    }

    .online, .fisico{
        display: flex;
        flex-direction: column;
        gap: 10px;

        .data{
            p{
                width: 40%;
            }
            display: flex;
            justify-content: flex-start;
            gap: 20px;
            align-items: center;
        }
    }
}