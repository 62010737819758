.main{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-top: 60px;


    justify-content: center;
    .logo{
        position: absolute;
        top: -60px;
        width: 130px;
        height: 130px;
        border-radius: 50%;
        background-color: white;
        border: 5px white solid;
        display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
        img{
            width: 75%;
            max-width: 130px;
            max-height: 130px;
        }
    }

    .greyWrapper{
        width: 200px;
        padding-top: 85px;
        background-color: #F5F5F5;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;


        .lista{
            min-height: 400px;
            max-height: 400px;
            overflow-y: auto;
            padding:0 10px;
            display: flex;
            flex-direction: column;
            gap: 5px;
            box-sizing: border-box;


            &::-webkit-scrollbar {
                width: 5px;
                height: 5px;
            }
    
            &::-webkit-scrollbar-track {
                border-radius: 10px;
            }
    
            &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                background-color: #a19b9b;
            }
        }
    }
}