.main{
    display: flex;
    justify-content: flex-start;
    gap: 16px;
    label{
        color: #8F8F8F;
    }
}

@media screen and (max-width: 915px) {
    .main{

        label{
            font-size: 14px;
        }
    }

}
