.main {
  width: 50%;
  min-width: 50%;
  background-color: #f5f5f5;
  border-radius: 10px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 30px;

  .otpInput {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;

    label {
      width: 20%;
    }

    .mui {
      input {
        background-color: white;
        height: 20px;
        width: 30px;
        border-radius: 8px;
      }
    }
  }

  .warning {
    .text {
      font-style: italic;
      color: #8f8f8f;
      margin: 0;
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;

    .resendCode {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
  }
}

@media screen and (max-width: 915px) {
  .main {
    width: 90%;
    padding: 20px;

    .otpInput {
      display: flex;
      flex-direction: column;
      label {
        width: 50%;
        text-align: center;
      }
      .mui {
        gap: 5px;
        input {
          background-color: white;
          height: 10px;
          width: 20px;
          border-radius: 8px;
        }
      }
    }

    .warning {
      .text {
        font-size: 14px;
      }
    }

    .footer {
        display: flex;
        flex-direction: column;
        gap:20px;
    
        .buttons{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;
        }

        .resendCode {
            width: 70%;
            display: flex;
            gap: 4px;
        }
      }
  }
}
